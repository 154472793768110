import { Locale, ProductsConfiguration, TrackingData, PageId, POT } from '@vp/pot-component'
import { useAppContext } from '../state/AppContext'
import PageConfig from '../utils/pageConfig'
import { useEffect, useState } from 'react'
import { BFFPageData } from '../services/serviceClients/BffClient'
import { formatLocale } from '../utils/buildHelpers'

interface PotContainerProps {
  pageDataFromBFF: BFFPageData;
}
const POTContainer = (props:PotContainerProps) => {
  const { locale, auth, vatInclusivity, merchandisingCategoryId } = useAppContext()
  const { pageDataFromBFF } = props
  const [isPotReady, setIsPotReady] = useState(false)
  const [productsConfiguration, setProductsConfiguration] = useState<ProductsConfiguration | undefined>(undefined)

  useEffect(() => {
    if (auth && pageDataFromBFF?.mpvs?.length > 0) {
      setProductsConfiguration(pageDataFromBFF.mpvs.map((mpv) => ({
        productId: mpv.coreProductId,
        quantity: mpv.quantities?.default,
      })))
      setIsPotReady(true)
    }
  }, [pageDataFromBFF, auth])

  const trackingData: TrackingData = {
    category: 'Category Page',
    pageSection: 'Category Page',
    pageStage: 'Discover',
    pageName: `${merchandisingCategoryId} :Category Page`,
  }

  const { requestor, tenant, developmentMode } = PageConfig

  return (
    isPotReady &&
      <POT
        userConfiguration={{
          authToken: auth.getAuthorization?.()?.token ?? auth.getToken?.(),
          vatInclusivity,
        }}
        tenantConfiguration={{
          tenant,
          locale: formatLocale(locale, true) as Locale,
        }}
        clientConfiguration={{
          requestor,
          pageId: PageId.Category,
        }}
        productsConfiguration={productsConfiguration as ProductsConfiguration}
        trackingData={trackingData}
        developmentMode={developmentMode}
      />
  )
}

export default POTContainer
