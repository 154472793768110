import {
  SWAN_STYLE_KEY_MAP,
} from '@vp/swan'

export const swanStyleKeys = [
  SWAN_STYLE_KEY_MAP.core,
  SWAN_STYLE_KEY_MAP.ratingsStars,
  SWAN_STYLE_KEY_MAP.progressBar,
  SWAN_STYLE_KEY_MAP.popover,
  SWAN_STYLE_KEY_MAP.accordion,
  SWAN_STYLE_KEY_MAP.pagination,
  SWAN_STYLE_KEY_MAP.icon,
  SWAN_STYLE_KEY_MAP.modalDialog,
  SWAN_STYLE_KEY_MAP.button,
  SWAN_STYLE_KEY_MAP.accordion,
  SWAN_STYLE_KEY_MAP.carousel,
  SWAN_STYLE_KEY_MAP.alertBox,
  SWAN_STYLE_KEY_MAP.grid,
  SWAN_STYLE_KEY_MAP.hidden,
  SWAN_STYLE_KEY_MAP.icon,
  SWAN_STYLE_KEY_MAP.toggleSwitch,
  SWAN_STYLE_KEY_MAP.visible,
  SWAN_STYLE_KEY_MAP.standardTile,
  SWAN_STYLE_KEY_MAP.progressiveImage,
  SWAN_STYLE_KEY_MAP.utility,
  SWAN_STYLE_KEY_MAP.selectionSet,
  SWAN_STYLE_KEY_MAP.anchorBar,
  SWAN_STYLE_KEY_MAP.popover,
  SWAN_STYLE_KEY_MAP.pricing,
  SWAN_STYLE_KEY_MAP.banner,
  SWAN_STYLE_KEY_MAP.listbox,
  SWAN_STYLE_KEY_MAP.spinner
]
