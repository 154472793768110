import { ShortMsxMpv } from '../../types/ShortMsxMpv'
import {
  CategoryPageSection,
  ProductGridType,
  ShortProductTileV2,
} from '../../types/TemplateConfiguration'
import { GoodBetterBestSection, GoodBetterBestValue } from '../../types/GoodBetterBest'
import { ShortProductTile } from '../../types/ShortProductTile'
import { ShortMerchandisingCategory } from '../../types/ShortMerchandisingCategory'
import { VirtualSection } from '../../types/VirtualSection'
import { useUserContextTracker } from '@vp/ubik-context'

interface ProductAnalyticsData {
  product_id: string;
  name: string;
  core_product_id: string;
  position: number;
  list_section_id: string;
}

export const buildProductAnalyticsData = (props: {
  sections: CategoryPageSection[];
  mpvs: ShortMsxMpv[];
}) => {
  const { sections, mpvs } = props
  const result: ProductAnalyticsData[] = []

  let position = 0
  sections.forEach((section, sectionIndex) => {
    switch (section.type) {
      case 'good better best': {
        const goodBetterBestValues: GoodBetterBestValue[] = [
          (section as GoodBetterBestSection).goodValue,
          (section as GoodBetterBestSection).betterValue,
          (section as GoodBetterBestSection).bestValue,
        ]
        goodBetterBestValues.forEach((value) => {
          const mpvId =
            // To accomodate attaching GMPV directly
            value?.mpvId ||
            // To accomodate old tiles with GMPV (old tiles with merchandising cateogory are not allowed in GBB)
            value?.merchandisingElement?.mpvId ||
            // To accomodate new product tile
            value?.template?.productsList?.selectedProduct?.mpvId ||
            value?.template?.category?.selectedCategory?.mpvId ||
            ''
          const mpv = mpvs.find((mpv) => mpv.mpvId === mpvId)
          if (mpvId) {
            result.push({
              product_id: mpvId,
              name: mpv?.name || value.template?.category?.selectedCategory?.name.value || '',
              core_product_id: mpv?.coreProductId || '',
              position: ++position, // Analytics uses 1-based indexing
              list_section_id: `Category Page:${sectionIndex + 1}`, // Analytics uses 1-based indexing
            })
          }
        })
        break
      }
      case 'product grid':
      case 'product grid horizontal':
      case 'product grid horizontal XL':
      case 'product lane':
        (section as ProductGridType).items.forEach((item) => {
          if (item.product) {
            const mpvId =
              // To accomodate attaching merchandising category and GMPV directly
              (item.product as ShortMsxMpv | ShortMerchandisingCategory).mpvId ||
              // To accomodate old tile
              (item.product as ShortProductTile).merchandisingElement?.mpvId ||
              // To accomodate new product tile
              (item.product as ShortProductTileV2).template?.productsList?.selectedProduct?.mpvId ||
              (item.product as ShortProductTileV2).template?.category?.selectedCategory?.mpvId ||
              ''
            const mpv = mpvs.find((mpv) => mpv.mpvId === mpvId)
            if (mpvId) {
              result.push({
                product_id: mpvId,
                name:
                  mpv?.name ||
                  (item.product as ShortMerchandisingCategory).name?.value ||
                  (item.product as ShortProductTile).merchandisingElement?.name?.value ||
                  (item.product as ShortProductTileV2).template?.category?.selectedCategory?.name
                    .value ||
                  '',
                core_product_id: mpv?.coreProductId || '',
                position: ++position, // Analytics uses 1-based indexing
                list_section_id: `Category Page:${sectionIndex + 1}`, // Analytics uses 1-based indexing
              })
            }
          }
        })
        break
      case 'virtual section': {
        const virtualSection = (section as VirtualSection).value
        const mpvIds = [
          virtualSection?.localizedFields?.find(
            (field) =>
              field.contentful_id === virtualSection?.value.browseOurDesignsMpv?.contentful_id
          )?.mpvId,
          virtualSection?.localizedFields?.find(
            (field) =>
              field.contentful_id === virtualSection?.value.uploadYourDesignMpv?.contentful_id
          )?.mpvId,
        ]
        mpvIds.forEach((mpvId) => {
          const mpv = mpvs.find((mpv) => mpv.mpvId === mpvId)
          if (mpvId) {
            result.push({
              product_id: mpvId,
              name: mpv?.name || '',
              core_product_id: mpv?.coreProductId || '',
              position: ++position, // Analytics uses 1-based indexing
              list_section_id: `Category Page:${sectionIndex + 1}`, // Analytics uses 1-based indexing
            })
          }
        })
        break
      }
      default:
        break
    }
  })

  return result
}

export const trackClickEvent = (props: {
  categoryId: string;
  pageZone: string;
  ctaValue: string;
  destinationUrl: string;
  index?: number;
  linkType?: string;
}) => {
  const { categoryId, pageZone, ctaValue, destinationUrl, index, linkType } = props

  const sourcePagePath = window.location.pathname

  let destinationPagePath = destinationUrl
  if (destinationUrl?.includes('https')) {
    destinationPagePath = new URL(destinationUrl).pathname
  }

  const navigationDetail = `${ctaValue}${index ? `:${index}` : ''}`
  const eventDetail = `${sourcePagePath};${destinationPagePath};${pageZone};${ctaValue}${
    linkType ? `;${linkType}` : ''
  }`

  // @ts-ignore
  const windowTracking = window.tracking as any
  if (windowTracking?.track) {
    windowTracking.track('Navigation Clicked', {
      category: 'Category Page',
      label: `Content:${pageZone}`,
      eventDetail,
      navigationDetail,
      pageSection: 'Category Page',
      pageStage: 'Discover',
      pageName: `${categoryId}:Category Page`,
    })
  }
}

export const trackMediaViewed = (props: {
  categoryId: string;
  videoId: string;
  videoUrl: string;
  mobileVideoUrl: string;
  name: string;
  progress: string;
}) => {
  const { categoryId, videoId, videoUrl, mobileVideoUrl, name, progress } = props

  // @ts-ignore
  const windowTracking = window.tracking as any
  if (windowTracking?.track) {
    windowTracking.track('Media Engaged', {
      category: 'Video',
      label: videoId, // Vista [VIDEO ID]- Unique Identifier
      eventDetail: 'viewed_' + progress,
      pageSection: 'Category Page',
      pageStage: 'Discover',
      pageName: `${categoryId}:Category Page`,
      name,
      videoUrl,
      mobileVideoUrl,
    })
  }
}

export const trackMediaClicked = (props: {
  categoryId: string;
  videoId: string;
  videoUrl: string;
  mobileVideoUrl: string;
  name: string;
  action: string;
}) => {
  const { categoryId, videoId, videoUrl, mobileVideoUrl, name, action } = props

  // @ts-ignore
  const windowTracking = window.tracking as any
  if (windowTracking?.track) {
    windowTracking.track('Media Engaged', {
      category: 'Video',
      label: videoId, // Vista [VIDEO ID]- Unique Identifier
      eventDetail: 'clicked_' + action,
      pageSection: 'Category Page',
      pageStage: 'Discover',
      pageName: `${categoryId}:Category Page`,
      name,
      videoUrl,
      mobileVideoUrl,
    })
  }
}

export const trackTemplateTileClickEvent = (props: {
  categoryId: string;
  pageZone: string;
  ctaValue: string;
  destinationUrl: string;
  index?: number;
  linkType?: string;
  itemsCount?: number;
  sectionTypeCount?: number;
}) => {
  const {
    categoryId,
    pageZone,
    ctaValue,
    destinationUrl,
    index,
    linkType,
    itemsCount,
    sectionTypeCount,
  } = props

  const sourcePagePath = window.location.pathname

  let destinationPagePath = destinationUrl
  if (destinationUrl?.includes('https')) {
    destinationPagePath = new URL(destinationUrl).pathname
  }

  // navigation detail - [CLICKED TILE POSITION]:[TOTAL TILES ON THE LANE]:[LANE NUMBER]
  const navigationDetail = `${index ? `${index}:${itemsCount}:${sectionTypeCount}` : ''}`
  const eventDetail = `${sourcePagePath};${destinationPagePath};${pageZone};${ctaValue}${
    linkType ? `;${linkType}` : ''
  }`

  // @ts-ignore
  const windowTracking = window.tracking as any
  if (windowTracking?.track) {
    windowTracking.track('Navigation Clicked', {
      category: 'Category Page',
      label: `Content:${pageZone}`,
      eventDetail,
      navigationDetail,
      pageSection: 'Category Page',
      pageStage: 'Discover',
      pageName: `${categoryId}:Category Page`,
    })
  }
}

export const trackCarouselClick = (props: {
  categoryId: string;
  pageZone: string;
  slideIndex: number;
}) => {
  const { categoryId, pageZone, slideIndex } = props

  const eventDetail = `Navigated to carousel slide ${slideIndex}`

  // @ts-ignore
  const windowTracking = window.tracking as any
  if (windowTracking?.track) {
    windowTracking.track('Carousel Arrow Clicked', {
      category: 'Category Page',
      label: `Content:${pageZone}`,
      eventDetail,
      pageSection: 'Category Page',
      pageStage: 'Discover',
      pageName: `${categoryId}:Category Page`,
    })
  }
}

export const trackAnchorBarClickEvent = (props: { categoryId: string; label: string }) => {
  const { categoryId } = props
  // @ts-ignore
  const windowTracking = window.tracking as any

  if (windowTracking?.track) {
    windowTracking.track('Link Clicked', {
      category: 'Category Page',
      label: 'Anchor Bar Link',
      pageSection: 'Category Page',
      pageStage: 'Discover',
      pageName: `${categoryId}:Category Page`,
    })
  }
}

export const trackUsedContext = (merchandisingCampaign: string, experimentKey?: string, variationKey?: string, treatment?: string) => {
  const tracker = useUserContextTracker()
  if (experimentKey) {
    tracker({
      type: 'experiment',
      name: experimentKey,
      variation: variationKey
    })
  }
  if (treatment) {
    tracker({
      type: 'treatment',
      name: treatment
    })
  }
  if (merchandisingCampaign) {
    tracker({
      type: 'campaign',
      name: merchandisingCampaign
    })
  }
}
