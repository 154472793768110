import {
  BannerWrapper,
  CloudinaryImageSrcProps,
  DefaultContentfulBannerWrapperProps,
  ITrackingProps,
} from '@vp/banner-wrapper'
import React from 'react'
import { BoundedContent } from '@vp/swan'

import { getPageZone } from './StandardHeroContent/StandardHeroContent'
import StandardHeroUploadFlowCTA from './StandardHeroContent/StandardHeroUploadFlowCTA'

import { SidebarType } from '../types/TemplateConfiguration'

import { useAppContext } from '../state/AppContext'
import { trackClickEvent } from '../utils/tracking/tracking'
import { getNewBannerUploadFlow } from '../utils/buildHelpers'

export interface BannerProps {
  bannerProps: DefaultContentfulBannerWrapperProps[];
  headerType: string;
  sectionNumber?: number;
  sidebar?: SidebarType;
  sidebarEnabled?: boolean;
  leftNav?: React.JSX.Element;
  useH1ForTitle?: boolean;
}

const CustomBannerWrapper = (props: BannerProps) => {
  const {
    bannerProps,
    headerType,
    sectionNumber,
    sidebar,
    sidebarEnabled,
    leftNav,
    useH1ForTitle

  } = props
  const pageZone =
    headerType === ('Side by Side Hero' as string)
      ? 'Side by Side Hero'
      : getPageZone(headerType, sectionNumber)

  const { merchandisingCategoryId, uploadFlowMpv } =
    useAppContext()

  bannerProps.forEach((banner) => {
    const updateAltText = (image: CloudinaryImageSrcProps) => {
      if (image && !image.altTextOverride) {
        image.altTextOverride = ''
      }
    }

    if (Array.isArray(banner.bannerImage)) {
      banner.bannerImage.forEach((image) => updateAltText(image.imageSrc as CloudinaryImageSrcProps))
    } else {
      updateAltText(banner.bannerImage.imageSrc as CloudinaryImageSrcProps)
    }
  })

  const ctaButtons = bannerProps[0].ctaButton
  let totalCtaCount = 0
  if (Array.isArray(ctaButtons)) {
    totalCtaCount = ctaButtons.length
  } else {
    totalCtaCount = 1
  }

  const getBannerMpvId = getNewBannerUploadFlow(bannerProps)
  const fullUploadFlowMpv = getBannerMpvId
    ? uploadFlowMpv
    : undefined
  let ctaButton

  if (fullUploadFlowMpv?.mpvId === getBannerMpvId) {
    ctaButton = (
      <StandardHeroUploadFlowCTA
        key='upload-flow-cta'
        pageZone={pageZone}
        uploadFlowMpv={fullUploadFlowMpv}
        totalCtaCount={totalCtaCount}
      />
    )
  }

  const trackBannerClickEvent = (
    event: React.MouseEvent<Element, MouseEvent>,
    {
      destinationUrl,
      linkIdentifier,
      ctaPosition,
      openInNewTab,
    }: ITrackingProps
  ) => {
    trackClickEvent({
      categoryId: merchandisingCategoryId,
      pageZone,
      ctaValue: `CTA ${ctaPosition}`,
      destinationUrl: destinationUrl ?? ' ',
    })
  }

  if (sidebarEnabled && sidebar) {
    return (
      <BoundedContent paddingX={0} marginBottom={{ xs: 7, sm: 7, md: 8 }}>
        <BannerWrapper
          bannerProps={bannerProps}
          trackingHandler={trackBannerClickEvent}
          leftNav={leftNav}
          gmpvFlowComponent={ctaButton}
          extraProps={{
            useH1ForTitle,
          }}
        />
      </BoundedContent>
    )
  }

  return (
    <BoundedContent paddingX={0} marginBottom={{ xs: 7, sm: 7, md: 8 }}>
      <BannerWrapper
        bannerProps={bannerProps}
        trackingHandler={trackBannerClickEvent}
        gmpvFlowComponent={ctaButton}
        extraProps={{
          useH1ForTitle,
        }}
      />
    </BoundedContent>
  )
}

export default CustomBannerWrapper
