import { createContext, useContext, useEffect, useState } from 'react'
import { ScreenClassProvider } from '@vp/swan'
import { CartTenantDetails } from '@vp/cart-client'
import { useIdentity } from '@vp/ubik-context'

// Pricing
import pricingContextModuleHelpers from '@vp/pricing-context-module/helpers'
import PricingContext from '../services/serviceClients/PricingContext'
import PcxtCookieProvider from '../utils/pcxtCookieProvider/pcxtCookieProvider'

import { ContentfulEntryLink, ReviewsResponse } from '../types/Contentful/ContentfulTypes'
import { MsxMpv } from '../types/Msx'

export type AppContextProps = {
  auth: any;
  backgroundColor: string;
  debugMode: boolean;
  dictionaryEntries: Record<string, string>;
  enableCampaignCallouts: boolean;
  environment: string;
  locale: string;
  merchandisingCategoryId: string;
  referencedFields: Record<string, ContentfulEntryLink>;
  reviewsData: ReviewsResponse | undefined;
  uploadFlowMpv?: MsxMpv;
  sidebarEnabled: boolean;
  vatInclusivity: boolean;
  vistacartTenant: CartTenantDetails | {};
  spaceId: string;
  contentfulId: string;
  isPagePartOfPrimarayContentExperiment: boolean,
  primaryContentExperimentVariation?: string
}

const AppContext = createContext({
  auth: undefined,
  backgroundColor: 'platinum',
  debugMode: false,
  dictionaryEntries: {},
  enableCampaignCallouts: false,
  environment: '',
  locale: '',
  merchandisingCategoryId: '',
  referencedFields: {},
  reviewsData: undefined,
  uploadFlowMpv: undefined,
  sidebarEnabled: false,
  vatInclusivity: false,
  vistacartTenant: {},
  spaceId: '',
} as AppContextProps)

const AppProvider = (props: {
  backgroundColor: string | undefined;
  children: any;
  defaultVatIncl: boolean;
  dictionaryEntries: Record<string, string>;
  enableCampaignCallouts: boolean;
  environment: string;
  reviewsData: ReviewsResponse;
  locale: string;
  merchandisingCategoryId: string;
  referencedFields: ContentfulEntryLink[];
  sidebarEnabled: boolean;
  uploadFlowMpv?: MsxMpv;
  vistacartTenant?: CartTenantDetails;
  spaceId: string;
  contentfulId: string;
  isPagePartOfPrimarayContentExperiment: boolean,
  primaryContentExperimentVariation?: string
}) => {
  const {
    backgroundColor,
    children,
    defaultVatIncl,
    dictionaryEntries,
    enableCampaignCallouts,
    environment,
    locale,
    merchandisingCategoryId,
    referencedFields,
    reviewsData,
    sidebarEnabled,
    uploadFlowMpv,
    vistacartTenant,
    spaceId,
    contentfulId,
    isPagePartOfPrimarayContentExperiment,
    primaryContentExperimentVariation
  } = props
  const [debugMode, setDebugMode] = useState(false)
  const [identity, setIdentity] = useState(undefined)
  const [vatInclusivity, setVatInclusivity] = useState(defaultVatIncl)

  const isPricingContextInitialized = PricingContext.isInitialized()
  const ubikAuth = useIdentity()

  useEffect(() => {
    const newIdentity = ubikAuth.auth?.getIdentity?.()
    if (newIdentity !== identity) {
      setIdentity(newIdentity)
    }
  }, [ubikAuth])

  useEffect(() => {
    if (isPricingContextInitialized) {
      setVatInclusivity(PcxtCookieProvider.getVat(defaultVatIncl))
      // Event listeners for pricing context
      document.addEventListener(
        pricingContextModuleHelpers.EVENTS.INITIALIZED_EVENT,
        (event) => {
          setVatInclusivity(PcxtCookieProvider.getVat(defaultVatIncl))
        },
        { once: true }
      )
      document.addEventListener(
        pricingContextModuleHelpers.EVENTS.VAT_INCLUSIVITY_CHANGE_EVENT,
        (event) => {
          setVatInclusivity(PcxtCookieProvider.getVat(defaultVatIncl))
        },
        false
      )
    }
  }, [isPricingContextInitialized])

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.search) {
      const params = new URLSearchParams(window.location.search)
      setDebugMode(params.has('debugPage'))
    }
  }, [])

  const referencedFieldsDictionary =
    referencedFields?.reduce((result: Record<string, ContentfulEntryLink>, field) => {
      result[field?.contentful_id] = field
      return result
    }, {}) || {}

  const appContextValue = {
    auth: ubikAuth.auth,
    backgroundColor: backgroundColor || 'platinum',
    debugMode,
    dictionaryEntries,
    enableCampaignCallouts,
    environment,
    locale,
    merchandisingCategoryId,
    referencedFields: referencedFieldsDictionary,
    reviewsData,
    uploadFlowMpv,
    sidebarEnabled,
    vatInclusivity,
    vistacartTenant,
    spaceId,
    contentfulId,
    isPagePartOfPrimarayContentExperiment,
    primaryContentExperimentVariation
  } as AppContextProps

  return (
    <AppContext.Provider value={appContextValue}>
      <ScreenClassProvider>{children}</ScreenClassProvider>
    </AppContext.Provider>
  )
}

const useAppContext = () => useContext(AppContext)

export { AppContext, AppProvider, useAppContext }
