import { useEffect, useState } from 'react'
import { getVariation, whenAvailable, fireImpression } from '@vp/ab-reader'
import { useAppContext } from '../../state/AppContext'

const useExperiment = <T extends string = string>(
  supportedVariations: T[],
  supportedLocales?: string[],
  supportedMerchandisingCategories?: string[],
  experimentKey?: string
): T | undefined => {
  const { locale, merchandisingCategoryId } = useAppContext()
  const [variationKey, setVariationKey] = useState<T | undefined>()

  const showExperiment: boolean =
    !!variationKey &&
    supportedVariations.includes(variationKey) &&
    (supportedLocales ? supportedLocales.includes(locale) : true) &&
    (supportedMerchandisingCategories
      ? supportedMerchandisingCategories.includes(merchandisingCategoryId)
      : true)

  useEffect(() => {
    let mounted = true
    if (experimentKey) {
      whenAvailable(() => {
        if (mounted) {
          const variation = (getVariation(experimentKey) as T) || undefined
          setVariationKey(variation)
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [experimentKey])

  useEffect(() => {
    if (showExperiment) {
      const analytics = async () => {
        if (experimentKey && variationKey) {
          fireImpression(experimentKey, variationKey)
        }
      }
      analytics()
    }
  }, [variationKey])

  return showExperiment ? variationKey : undefined
}

export default useExperiment
