import React, { createContext, useContext } from 'react'
import { RegisteredTests } from '../ab-tests/utils/testRegistry'
import useExperiment from '../ab-tests/utils/useExperiment'

type ExperimentsContextValue = {
  [testKey in RegisteredTests]: any;
}
interface ExperimentsProviderProps {
  children: React.ReactNode;
}

const ExperimentsContext = createContext<ExperimentsContextValue>({
  [RegisteredTests.YOUR_EXPERIMENT_KEY]: {},
})

const supportedVariations = [
  'your_variation_key_1',
  'your_variation_key_2',
]
const supportLocales = ['en-US', 'es-US']
const supportedMerchandisingCategories = ['businessCards']

const ExperimentsProvider = ({
  children,
} : ExperimentsProviderProps) => {
  const variationKey = useExperiment(
    supportedVariations,
    supportLocales,
    supportedMerchandisingCategories,
    RegisteredTests.YOUR_EXPERIMENT_KEY
  )

  const testData = {
    your_experiment_key: {
      variationKey,
      // ...otherRequiredData
    }
  }

  const value = {
    [RegisteredTests.YOUR_EXPERIMENT_KEY]: testData,
  }

  return (
    <ExperimentsContext.Provider value={value}>{children}</ExperimentsContext.Provider>
  )
}

export const useExperimentsContext = () => useContext(ExperimentsContext)

export default ExperimentsProvider
