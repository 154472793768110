import { Banner, BoundedContent } from '@vp/swan'
import React from 'react'
import { useAppContext } from '../state/AppContext'
import StandardHeroContent from './StandardHeroContent/StandardHeroContent'
import { DebugPageSections } from './debugComponents/DebugPageSections'
import CustomBannerWrapper from './Banner'
import { MARKETING_COLORS } from '@vp/marketing-colors'

interface PromoBannerProps {
  configuration: any;
  sectionNumber: number;
}

const PromoBanner = (props: PromoBannerProps): React.JSX.Element => {
  const { configuration, sectionNumber } = props
  // Prior to CDS-13, it was not possible to add background color per marquee
  // This line is to enable backwards compatibility for the same
  let { backgroundColor } = useAppContext()
  const fullWidthImage = !!configuration.fullWidthImage
  if (configuration?.backgroundColor?.name) {
    backgroundColor = configuration?.backgroundColor?.name
  }
  const newBanner = configuration?.banner

  if (newBanner) {
    if (newBanner.bannerTemplate) {
      return (
        <>
          <CustomBannerWrapper
            bannerProps={newBanner.bannerTemplate}
            headerType={configuration.headerType}
            sectionNumber={sectionNumber}
          />
          <DebugPageSections rawSectionData={configuration} />
        </>
      )
    } else {
      return <></>
    }
  }

  return (
    <>
      <BoundedContent paddingX={0} marginBottom={{ xs: 7, sm: 7, md: 8 }}>
        <Banner
          id={1234}
          imageFocalPoint={configuration.imageFocalPoint ?? 'right bottom'}
          textBoxHorizontalAlign='right'
          style={
            {
              '--swan-public-marketing-background':
                MARKETING_COLORS[backgroundColor]?.code,
            } as React.CSSProperties
          }
          darkMode={MARKETING_COLORS[backgroundColor]?.mode.dark}
          variant={fullWidthImage ? 'full-width-image' : 'card'}
        >
          <StandardHeroContent
            {...configuration}
            sectionNumber={sectionNumber}
          />
        </Banner>
        <DebugPageSections rawSectionData={configuration} />
      </BoundedContent>
    </>
  )
}

export default PromoBanner
