import React from 'react'
import {
  Callout,
  Column,
  FluidImage,
  H2,
  Hidden,
  Link,
  Row,
  StandardTile,
  StandardTileContents,
  StandardTileImage,
  StandardTileName,
  StandardTileOverlay,
  StandardTilePrice,
  StandardTileSeparatelyClickableContents,
  Button,
  Typography,
  Box,
  StyleSpaceWithAuto,
  StandardTileDescription,
} from '@vp/swan'
import { useStyles } from '@vp/ubik-context'
import { CampaignCallout } from '@vp/campaign-callouts'
import { CouponCallout } from '@vp/pot-component'
import { PriceDisplay } from '@vp/price-platform'

import {
  GoodBetterBestTile,
  GoodBetterBestValue,
  GoodBetterBestSection,
  CalloutSkin,
  CalloutVariant,
} from '../types/GoodBetterBest'
import {
  buildGoodBetterBestTiles,
  resolveGbbTileCallout,
  showGoodBetterBestSection,
} from '../utils/goodBetterBest/goodBetterBest'
import { stylesheet } from '../styles/GoodBetterBest.scss'
import { useAppContext } from '../state/AppContext'
import classNames from 'classnames'
import { isProductTileInStock } from '../utils/ProductTile/productTile'
import { trackClickEvent } from '../utils/tracking/tracking'
import { GoodBetterBestError } from './errorComponents/GoodBetterBestError'
import { DebugPageSections } from './debugComponents/DebugPageSections'
import { MSXAttibuteValue } from '../types/Msx'
import BulletPointsDescription from './BulletPointsDescription'
import { BFFPageData } from '../services/serviceClients/BffClient'

interface GoodBetterBestProps {
  section: GoodBetterBestSection;
  pageDataFromBFF: BFFPageData;
}

const navigateTo = (url: string | undefined, e: Event) => {
  e?.preventDefault()
  if (url) {
    window.location.href = url
  }
}

const GoodBetterBest:React.FC<GoodBetterBestProps> = (props: GoodBetterBestProps) => {
  useStyles(stylesheet)
  const {
    dictionaryEntries,
    enableCampaignCallouts,
    merchandisingCategoryId,
    referencedFields,
    debugMode,
    locale
  } = useAppContext()
  const { section, pageDataFromBFF } = props

  const goodBetterBestValues: GoodBetterBestValue[] = [
    section.goodValue,
    section.betterValue,
    section.bestValue,
  ]

  const goodBetterBestTiles = buildGoodBetterBestTiles(
    goodBetterBestValues,
    pageDataFromBFF,
    referencedFields,
    dictionaryEntries,
    locale
  )

  if (!showGoodBetterBestSection(goodBetterBestTiles)) {
    return debugMode ? <GoodBetterBestError /> : <></>
  }

  return (
    <>
      <Box className='good-better-best-wrapper'>
        {section.title?.value && (
          <Row my='3'>
            <Column span={12}>
              <H2 fontSize='x2large'>{section.title.value}</H2>
            </Column>
          </Row>
        )}
        {section.subtitle?.value && (
          <Row mb={8}>
            <Column span={12}>
              <Typography>{section.subtitle.value}</Typography>
            </Column>
          </Row>
        )}
        <Row className='good-better-best-row' data-testid='gbb-tiles-container'>
          {goodBetterBestTiles.map((tile, index) => {
            const callout = resolveGbbTileCallout(
              tile,
              dictionaryEntries.outOfStock
            )
            const isTileInStock = tile ? isProductTileInStock(tile) : false
            const gbbTile = createGbbTile({
              tile: tile!,
              index,
              callout,
              isTileInStock,
              merchandisingCategoryId,
              enableCampaignCallouts,
              productId: tile?.productId,
              colorInformation: tile?.colorInformation,
            })
            return gbbTile
          })}
        </Row>
      </Box>
      <DebugPageSections
        rawSectionData={section}
        resolvedSectionData={goodBetterBestTiles}
      />
    </>
  )
}

const createGbbTile = (props: {
  tile: GoodBetterBestTile;
  index: number;
  callout?: {
    skin: CalloutSkin;
    callout: string;
    variant: CalloutVariant;
  };
  isTileInStock: boolean;
  merchandisingCategoryId: string;
  enableCampaignCallouts: boolean;
  productId?: string;
  colorInformation?: MSXAttibuteValue[];
}) => {
  const {
    tile,
    index,
    callout,
    isTileInStock,
    merchandisingCategoryId,
    enableCampaignCallouts,
    productId,
  } = props

  const tileConfiguration = {
    className: classNames('gbb-tile', {
      'tile-disabled': !isTileInStock,
    }),
    skin: 'product' as 'standard' | 'product' | 'circular',
    imageWidth: 'full-bleed' as 'standard' | 'full-bleed',
  }

  const coveringLinkConfiguration = {
    href: tile!.transformedPrimaryUrl,
    covering: true,
    onClick: (e: any) => {
      trackClickEvent({
        categoryId: merchandisingCategoryId,
        pageZone: 'Good Better Best',
        ctaValue: 'Tile',
        destinationUrl: tile!.transformedPrimaryUrl!,
        index: index + 1,
      })
      navigateTo(tile!.transformedPrimaryUrl, e)
    },
    'data-section': 'Good Better Best',
    'data-position': index + 1,
    'data-translation': tile.mpvId || 'Tile',
  }

  const primaryButtonConfiguration = {
    my: 4 as StyleSpaceWithAuto,
    onClick: (e: any) => {
      trackClickEvent({
        categoryId: merchandisingCategoryId,
        pageZone: 'Good Better Best',
        ctaValue: 'CTA 1',
        destinationUrl: tile!.transformedPrimaryUrl!,
        index: index + 1,
      })
      navigateTo(tile!.transformedPrimaryUrl, e)
    },
    'data-section': 'Good Better Best',
    'data-position': index + 1,
    'data-translation': tile.mpvId || 'CTA 1',
    href: tile!.transformedPrimaryUrl,
    skin: 'unstyled',
  }

  const secondaryButtonConfiguration = {
    onClick: (e: any) => {
      trackClickEvent({
        categoryId: merchandisingCategoryId,
        pageZone: 'Good Better Best',
        ctaValue: 'CTA 2',
        destinationUrl: tile!.transformedSecondaryUrl!,
        index: index + 1,
      })
      navigateTo(tile!.transformedSecondaryUrl, e)
    },
    'data-section': 'Good Better Best',
    'data-position': index + 1,
    'data-translation': tile.mpvId || 'CTA 2',
    href: tile!.transformedSecondaryUrl,
  }

  const lines = tile!.description?.split('\n')

  return (
    <Column span={4} spanXs={12} key={`GoodBetterBestTile${index}`} flexColumn>
      <Hidden xs className='gbb-hidden'>
        <StandardTile mb='5' layout='vertical' {...tileConfiguration}>
          <StandardTileOverlay>
            {callout && (
              <Callout skin={callout.skin} variant={callout.variant}>
                {callout.callout}
              </Callout>
            )}
            {enableCampaignCallouts && (
              <CampaignCallout product={tile!.mpvId ?? ''} />
            )}
          </StandardTileOverlay>
          <StandardTileImage
            className={classNames({
              'tile-disabled-image': !isTileInStock,
            })}
          >
            <FluidImage src={tile!.imageUrl} alt={tile!.name || ''} />
          </StandardTileImage>
          <StandardTileContents
            className={classNames('gbb-tile-contents', {
              'tile-disabled-contents': !isTileInStock,
            })}
          >
            <Link {...coveringLinkConfiguration} className='gbb-tile-name'>
              <StandardTileName>{tile!.name}</StandardTileName>
            </Link>
            <StandardTileDescription className='good-better-best-tile-description'>
              <BulletPointsDescription text={lines} />
            </StandardTileDescription>
            {tile.showPrice && tile.mpvId && (
              <StandardTilePrice>
                <PriceDisplay mpvId={tile.mpvId} />
              </StandardTilePrice>
            )}
            {productId && (
              <StandardTileSeparatelyClickableContents className='pot-margin-remove'>
                <CouponCallout productId={productId} />
              </StandardTileSeparatelyClickableContents>
            )}
            {tile!.transformedSecondaryUrl && tile!.secondaryUrlText && (
              <StandardTileSeparatelyClickableContents>
                <Link skin='cta' {...secondaryButtonConfiguration} mt='5'>
                  {tile!.secondaryUrlText || ''}
                </Link>
              </StandardTileSeparatelyClickableContents>
            )}
            {tile!.primaryUrlText && (
              <Button
                {...primaryButtonConfiguration}
                skin='secondary'
                size='standard'
                my='2'
              >
                {tile!.primaryUrlText}
              </Button>
            )}
          </StandardTileContents>
        </StandardTile>
      </Hidden>
      <Hidden sm md lg xl className='gbb-hidden'>
        <StandardTile mb='5' layout='horizontal' {...tileConfiguration}>
          <StandardTileOverlay>
            {callout && (
              <Callout skin={callout.skin} variant={callout.variant}>
                {callout.callout}
              </Callout>
            )}
            {enableCampaignCallouts && (
              <CampaignCallout product={tile!.mpvId ?? ''} />
            )}
          </StandardTileOverlay>
          <StandardTileImage>
            <FluidImage src={tile!.imageUrl} alt={tile!.name || ''} />
          </StandardTileImage>
          <StandardTileContents
            className={classNames('gbb-tile-contents', {
              'tile-disabled-contents': !isTileInStock,
            })}
          >
            <Link {...coveringLinkConfiguration} className='gbb-tile-name'>
              <StandardTileName>{tile!.name}</StandardTileName>
            </Link>
            {/* <StandardTileDescription className='good-better-best-tile-description'>
              <BulletPointsDescription text={lines} />
            </StandardTileDescription> */}
            {tile.showPrice && tile.mpvId && (
              <StandardTilePrice>
                <PriceDisplay mpvId={tile.mpvId} />
              </StandardTilePrice>
            )}
            {productId && (
              <StandardTileSeparatelyClickableContents className='pot-margin-remove'>
                <CouponCallout productId={productId} />
              </StandardTileSeparatelyClickableContents>
            )}
            {tile!.transformedSecondaryUrl && tile!.secondaryUrlText && (
              <StandardTileSeparatelyClickableContents>
                <Link {...secondaryButtonConfiguration}>
                  {tile!.secondaryUrlText}
                </Link>
              </StandardTileSeparatelyClickableContents>
            )}
            {tile!.primaryUrlText && (
              <Button
                {...primaryButtonConfiguration}
                skin='secondary'
                size='mini'
              >
                {tile!.primaryUrlText}
              </Button>
            )}
          </StandardTileContents>
        </StandardTile>
      </Hidden>
    </Column>
  )
}

export default GoodBetterBest
