import { CategoryPageSectionType } from '../../types/TemplateConfiguration'

enum RegisteredTests {
  YOUR_EXPERIMENT_KEY = 'your_experiment_key',
}

type TestRegistry = {
  [testKey in RegisteredTests | string]: {
    [variationKey: string]: {
      sectionType?: CategoryPageSectionType;
      position?: number;
      component: (props: any) => JSX.Element;
    }[];
  };
}

const testRegistry: TestRegistry = {
  [RegisteredTests.YOUR_EXPERIMENT_KEY]: {},
}

const getRegisteredTest = (experimentKey: string, variationKey: string) => {
  if ((Object.values(RegisteredTests) as string[]).includes(experimentKey)) {
    return testRegistry[experimentKey][variationKey]
  }
}

export { RegisteredTests, getRegisteredTest }
