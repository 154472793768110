import PageSectionWrapper from '../../components/PageSectionWrapper'
import { pageCategoryPageV3 } from '../../types/Contentful/ContentfulTypes'
import { BFFPageData } from '../../services/serviceClients/BffClient'
import { getRegisteredTest } from './testRegistry'

export interface ExperimentData {
  [key: string]: {
    variationKey?: string;
    [key: string]: any;
  };
}

const getActiveExperiments = (experimentData: ExperimentData) => {
  return Object.keys(experimentData).filter(
    (experimentKey) => !!experimentData[experimentKey]?.variationKey
  )
}

export const TestSection = (experimentData: ExperimentData, sectionType: string) => {
  const activeExperimentKeys = getActiveExperiments(experimentData)

  for (const experimentKey of activeExperimentKeys) {
    const variationKey = experimentData[experimentKey]?.variationKey
    const registeredTest = variationKey
      ? getRegisteredTest(experimentKey, variationKey)
      : undefined
    const sectionOverride = (registeredTest || []).find((test) => test.sectionType === sectionType)
    if (sectionOverride && experimentData.isPageSection) {
      return sectionOverride.component
    }
  }
}

export const addNewTestSections = (props: {
  experimentData: ExperimentData;
  sections: (false | JSX.Element | null | undefined | '')[];
  pageDataFromBFF: BFFPageData;
  pageCategoryPageV3: pageCategoryPageV3;
  locale: string;
}) => {
  const { experimentData, sections, pageDataFromBFF, pageCategoryPageV3 } = props
  const activeExperimentKeys = getActiveExperiments(experimentData)

  for (const experimentKey of activeExperimentKeys) {
    const variationKey = experimentData[experimentKey]?.variationKey
    const isPageSection = experimentData[experimentKey]?.isPageSection ?? true
    const registeredTest = variationKey
      ? getRegisteredTest(experimentKey, variationKey)
      : undefined
    const sectionOverride = (registeredTest || []).find((test) => !!test.position)
    if (sectionOverride) {
      const position = sectionOverride.position!
      const NewSection = sectionOverride.component
      const sectionProps = { pageDataFromBFF, pageCategoryPageV3 }

      const WrappedNewSection = isPageSection
        ? (
          <PageSectionWrapper key={`test-section-${position}`}>
            <NewSection {...sectionProps} />
          </PageSectionWrapper>
          )
        : (
          <NewSection {...sectionProps} />
          )
      sections.splice(position, 0, WrappedNewSection)
    }
  }

  return sections
}
